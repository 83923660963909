import React, { useEffect } from "react"
import Logo from "./logoLarge";  

const ScrollingLogo = () => {
  useEffect(() => {
    var percent;  
    var multiply = (window.innerWidth-80)/65;
    if(multiply > 20) {
      multiply = 20;
    }
    var menu = document.querySelector('.masthead .menu');
    var logo = document.querySelector('.scrolling--logo');
    var headerLogo = document.querySelector('.header--logo');
    var top = document.querySelector('.logo--container')?.offsetTop;
    function callback () {
        percent = (window.pageYOffset + 32.5)/(top);
        if(percent > 1) {
          percent = 1; 
        }
        if(percent===1) { 
          logo.style.opacity=0;
          headerLogo.style.opacity=1;
          menu.classList.remove('hidden');
        } else {
          logo.style.opacity=1;
          headerLogo.style.opacity=0;
          menu.classList.add('hidden');
        }
        percent = 1 +(multiply - multiply*percent);
        logo.style.transform='scale('+percent+')'
        window.requestAnimationFrame(callback)
    }
    window.requestAnimationFrame(callback)
  },[])
  return (
  <div className='scrolling-logo--container'>
    <div className="logo--container">
      <Logo />
    </div>
  </div> 
  )  
}
export default ScrollingLogo
