import * as React from "react"

const LogoLarge = () => (
  <svg className="pointer-none scrolling--logo" width="65" height="16" viewBox="0 0 65 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.7433 15V0.820019H12.4833L7.94331 11.64L3.34331 0.820019H0.143311V15H2.48331V4.60002L6.86331 15H8.94331L13.3633 4.56002V15H15.7433Z" fill="white"/>
    <path d="M20.7472 7.90002C20.7472 4.48002 23.1072 2.78002 25.4872 2.78002C27.8872 2.78002 30.2472 4.48002 30.2472 7.90002C30.2472 11.32 27.8872 13.02 25.4872 13.02C23.1072 13.02 20.7472 11.32 20.7472 7.90002ZM18.2872 7.90002C18.2872 12.56 21.7672 15.3 25.4872 15.3C29.2072 15.3 32.7072 12.56 32.7072 7.90002C32.7072 3.26002 29.2072 0.52002 25.4872 0.52002C21.7672 0.52002 18.2872 3.26002 18.2872 7.90002Z" fill="white"/>
    <path d="M44.9717 3.08002V0.820019H33.3517V3.08002H37.9517V15H40.3517V3.08002H44.9717Z" fill="white"/>
    <path d="M49.3802 15V0.820019H46.9402V15H49.3802Z" fill="white"/>
    <path d="M57.84 11.86L53.88 0.820019H51.28L56.56 15H59L64.36 0.820019H61.82L57.84 11.86Z" fill="white"/>
  </svg>

) 
export default LogoLarge
