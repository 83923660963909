import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import { Swiper, SwiperSlide } from 'swiper/react'

const LogoCarousel = ({ title, logos }) => (
	<div className='logo--carousel p40 pr0 flex align-center m-wrap'>
		<div className='w-25 m-100 m-mb40'>
			<p className='small book uppercase white-space'>{title}</p>
		</div>
		<div className='w-75 m-100'>
			<Swiper spaceBetween={60} slidesPerView={2.5} speed={650} loop={true} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[Autoplay]} breakpoints={{ 768: { slidesPerView: 2.5, spaceBetween: 20 }, 1024: { slidesPerView: 4.5, spaceBetween: 30 } }}>
				{logos?.map(logo => (
					<SwiperSlide key={logo.originalId}>
						<div className='ratio-1-1 pos-rel max-125 ma select-none'>
							<GatsbyImage className='bg-image contain' image={logo.gatsbyImageData} alt='About Image' />
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	</div>
)

export default LogoCarousel
