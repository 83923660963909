import * as React from "react"
import ArrowLink from "./arrowLink";
import Spacer from "./spacer";


const Introduction = ({title, services}) => {
  const service = services.map(({ title, copyline }, index) => {
    return (
      <div className="service--item bt1 flex pt20 pb20 align-center m-flex-col  " key={index}>
        <div className="w-50 m-100 m-mb10  ">
          <p className="m0 h4 m-fs-16 ">{title}</p>
        </div>
        <div className="w-50  m-100 ">
          <p className="m0">{copyline} </p>
        </div>
      </div>
    )
  })
  return (
  <div className="projects p40 will--fade">
    <div className="bt1 pt20">
     <p className="m0 uppercase book small">Services</p>
     <div className="content mt40 h3 content--bolded  " dangerouslySetInnerHTML={{'__html' : title}}></div>
    </div>
    <div className="service--list mt80 bb1 mb40 m-mt0 m-mb0   ">
      <Spacer className='m-h-60 m-show  '/>
      {service}
     
    </div>
    <Spacer className='m-h-60 m-show '/>  
    <div className="link flex">
      <div className="w-50 m-hide ">
      </div>
      <div className="w-50 m-100 ">
        <ArrowLink to="/services" text="More about our services" />
      </div>
    </div>
  </div>
  )
} 
export default Introduction
