import React, { useRef, useState } from 'react'
import ArrowLink from '../components/arrowLink'
import Spacer from '../components/spacer'
import Link from './link'

import { useMobile } from '../hooks/mobile.js'

export default function Enquiry({ title, titleTwo, subtitle, subtitleTwo, dark, light }) {
	const [section, setSection] = useState(title)

	const changeSection = id => {
		if (section === id) return null
		setSection(id)
	}

	const isMobile = useMobile()

	const stateProps = { section, changeSection }
	return (
		<div className='enquiry p40'>
			<div className='max-1200 overflow ma'>
				<FirstBlock {...stateProps} title={title} light={light} dark={dark} subtitle={subtitle} />
				<SecondBlock {...stateProps} titleTwo={titleTwo} light={light} dark={dark} subtitleTwo={subtitleTwo} />
				<Spacer />
				{!isMobile && <div className='max-450 m-hide ma text-center signifier lh-14'>{section === title ? subtitle : subtitleTwo}</div>}
			</div>
		</div>
	)
}

const FirstBlock = ({ section, changeSection, light, subtitle, dark, title }) => {
	const isMobile = useMobile()
	return (
		<button onMouseOver={() => changeSection(title)} className={`button ${isMobile ? 'mobile' : ''} block-flex w-100 first-enquiry-section ${section === title ? 'active' : ''} pb20 bb1 ${light ? 'light' : ''}`}>
			<div className='enquiry-title'>
				<ArrowLink to='/contact' className={dark ? 'dark' : ''} text={title} />
			</div>
			{isMobile && <div className='max-450 mb20 mt20 signifier text-left lh-14'>{subtitle}</div>}
			<Link to='/contact' className={`mla link m0 m-underline enquiry-content ${dark ? 'dark' : ''}`}>
				Contact us
			</Link>
		</button>
	)
}

const SecondBlock = ({ section, changeSection, titleTwo, subtitleTwo, light, dark }) => {
	const isMobile = useMobile()
	return (
		<div onMouseOver={() => changeSection(titleTwo)} className={`button mt20 flex w-100 gap-30 second-enquiry-section ${section === titleTwo ? 'active' : ''} ${light ? 'light light-fields' : ''} gap-10 ${isMobile ? 'mobile' : ''}`}>
			<div className='enquiry-title text-left max-fit'>
				<ArrowLink to='#d' className={dark ? 'dark' : ''} text={titleTwo} />
			</div>
			{isMobile && <div className='max-450 mb20 mt20 signifier text-left lh-14'>{subtitleTwo}</div>}
			<div className='enquiry-content mla w-100'>
				<Form name='enquiry' className=''>
					<div className='flex flex-wrap w-100 m-flex-col enquiry-form-wrapper gap-5'>
						<input type='text' className='enquiry-input' placeholder='First Name' name='First Name' />
						<input type='text' className='enquiry-input' placeholder='Surname' name='Surname' />
						<input type='email' className='enquiry-input ' placeholder='Enter Your Email' name='Email' />
						<div className='w-100 flex m-flex-col gap-20'>
							<div className='radio-wrapper enquiry-input m-flex-col m-align-start'>
								<label htmlFor='' className='title m-mb10 text-grey'>
									How would you best describe yourself?
								</label>
								<div id='description' className='flex mla m-flex-col gap-30 m-gap-y-10'>
									<div className='flex gap-10px align-center text-grey'>
										<div className='radio-input-wrapper flex '>
											<input id='property-industry-professional' type='radio' name='description' value='Property Industry Professional' />
										</div>
										<label htmlFor='property-industry-professional' className='radio-label'>
											Property Industry Professional
										</label>
									</div>
									<div className='flex gap-10px align-center text-grey'>
										<div className='radio-input-wrapper flex'>
											<input id='purchaser' type='radio' name='description' value='Purchaser' />
										</div>
										<label htmlFor='purchaser' className='radio-label mr10 '>
											Purchaser
										</label>
									</div>
								</div>
							</div>
							<button className={`button mr10 m-m0 m-mt20 m-underline ${dark ? 'dark' : ''}`} type='submit'>
								Subscribe
							</button>
						</div>
					</div>
				</Form>
			</div>
		</div>
	)
}

const Form = ({ children, className = '', name = 'contact' }) => {
	const formRef = useRef(null)
	const [isFormSubmitted, setIsFormSubmitted] = useState(false)

	const handleSubmit = event => {
		event.preventDefault()
		const myForm = event.target
		const formData = new FormData(myForm)
		fetch('/', { method: 'POST', headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, body: new URLSearchParams(formData).toString() })
			.then(() => setIsFormSubmitted(true))
			.catch(error => alert(error))
	}

	return (
		<form method='POST' ref={formRef} onSubmit={e => handleSubmit(e)} data-netlify='true' name={name} netlify-honeypot='bot-field' className='form pos-rel'>
			<input type='hidden' name='bot-field' />
			<input type='hidden' name='form-name' value={name} />
			{!isFormSubmitted && <div className={`form-items ${className}`}>{children}</div>}
			{isFormSubmitted && (
				<div className={`form-submission-message m-h100 m-flex m-flex-column m-align-center m-justify-center z-2 `}>
					<p className='m0 p20 pt5'>Thanks for submitting the form, we'll get back to you soon</p>
				</div>
			)}
		</form>
	)
}
