import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Link from './link'

const Perspectiv = ({ subtitle, articles }) => (
	<div className='p40'>
		<div className='title-area text-center'>
			<p className='m0 h2-a pb20 bb1'>Perspectiv.</p>
			<div className='mt20 signifier'>{subtitle}</div>
		</div>
		<div className='grid grid-4 mt40 pt40'>
			{articles.nodes.map((article, index) => {
				return <Article article={article} key={index} />
			})}
		</div>
	</div>
)
export default Perspectiv

const Article = ({ article }) => {
	return (
		<Link to={`/perspective/${article.slug}`} className='link flex flex-col article-card'>
			<div className='pos-rel overflow-hidden ratio-3-2 p20'>
				<span className='tag'>{article.category[0].title}</span>
				<GatsbyImage image={article.image?.gatsbyImageData} alt='' class='bg-image image--zoom' />
			</div>
			<div className='max-300'>
				<p className='small text-grey mb20 mt16'>{article.meta.createdAt}</p>
				<p className=' mb20 balance'>{article.title}</p>
				<p to={`/perspective/${article.slug}`} className='read--more fs-small link flex align-center'>
					<span />
					Read More ({article.readTime})
				</p>
			</div>
		</Link>
	)
}
