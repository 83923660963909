import * as React from 'react'

class EnquiryForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			formSubmitted: false,
			message: '',
		}
	}
	componentDidMount() {
		document.querySelector('#newsletter').addEventListener('submit', this.handleSubmit)
	}

	handleSubmit = e => {
		e.preventDefault()
		let myForm = document.getElementById('newsletter')
		let formData = new FormData(myForm)
		if (formData.get('Email')) {
			console.log('test fetch')
			fetch('/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: new URLSearchParams(formData).toString(),
			})
				.then(() => {
					const form = document.querySelector('form')
					form.classList.add('fade-out')
					setTimeout(() => {
						this.setState({ formSubmitted: true }, () => {
							const thankYou = document.querySelector('#thank-you')
							setTimeout(() => {
								thankYou.classList.add('fade-in-thank-you')
							}, 100)
						})
					}, 1250)
				})
				.catch(error => console.error(error))
			document.querySelector('#newsletter').addEventListener('submit', this.handleSubmit)
		}
	}

	render() {
		const formSubmitted = this.state.formSubmitted

		const closeMenu = () => {
			document.body.classList.remove('newsletter-open')
		}

		if (!formSubmitted) {
			return (
				<div className='form text-center'>
					<form method='POST' data-netlify='true' name='newsletter' netlify-honeypot='bot-field' id='newsletter' className='contact--form max-450 ma'>
						<input type='hidden' name='bot-field' />
						<input type='hidden' name='form-name' value='newsletter' />
						<div className='flex m-wrap gap-20'>
							<input required={true} name='First Name' type='text' className='w-100 newsletter-input input' placeholder='First Name' />
							<input required={true} name='Surname' type='text' className='w-100 newsletter-input input' placeholder='Surname' />
						</div>
						<div className='flex'>
							<input required={true} name='Email' type='text' className='w-100 newsletter-input input' placeholder='Enter Your Email' />
						</div>
						<div className='radio-wrapper mb30 flex-col m-align-start'>
							<label htmlFor='' className='block radio-title mb20 text-grey'>
								How would you best describe yourself?
							</label>
							<div id='description' className='flex flex-col gap-10px'>
								<div className='flex gap-10px align-center text-grey'>
									<div className='radio-input-wrapper flex '>
										<input id='property-industry-professional-newsletter' className='light' type='radio' name='description' value='Property Industry Professional' />
									</div>
									<label htmlFor='property-industry-professional-newsletter' className='radio-label'>
										Property Industry Professional
									</label>
								</div>
								<div className='flex gap-10px align-center text-grey'>
									<div className='radio-input-wrapper flex'>
										<input id='purchaser-newsletter' className='light' type='radio' name='description' value='Purchaser' />
									</div>
									<label htmlFor='purchaser-newsletter' className='radio-label'>
										Purchaser
									</label>
								</div>
							</div>
						</div>
						<div className='flex'>
							<button type='submit' className='button newsletter-button'>
								Submit
							</button>
						</div>
					</form>
				</div>
			)
		} else {
			return (
				<div className='form-section mt40 mb20 text-center' id='thank-you'>
					<p className='m0 op-50'>Thank you for subscribing. We'll be in touch soon.</p>
				</div>
			)
		}
	}
}

export default EnquiryForm
