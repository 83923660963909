import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import ArrowLink from '../components/arrowLink'
import Spacer from '../components/spacer'
import Link from './link'

const ProjectGrid = ({ projects, title, count }) => (
	<div className='projects p40 m-pb0 '>
		<div className='bt1 pt20 will--fade'>
			<p className='m0 uppercase small book'>Projects</p>
			<div className='content mt40 h3 max-1200'>{title}</div>
		</div>
		<Spacer />
		<div className='flex gap-30 m-wrap'>
			<div className='w-50 m-100'>
				<ProjectFeature project={projects[0]} />
			</div>
			<div className='w-50 m-100 grid grid-2'>
				{projects.slice(1).map((project, index) => {
					return <ProjectCard project={project} key={index} />
				})}
			</div>
		</div>
		<div className='mt40'>
			<ArrowLink to='/projects' text='More Projects' sup={count} />
		</div>
	</div>
)
export default ProjectGrid

const ProjectFeature = ({ project }) => {
	return (
		<Link className='link' to={'projects/' + project.slug}>
			<div className='project-card'>
				<div className='ratio-1-1 pos-rel bg-grey overflow'>
					<GatsbyImage className='bg-image image--zoom' image={project.heroImage.gatsbyImageData} alt='Project Image' />
				</div>
				<div className='info'>
					<p className='uppercase book'>{project.name}</p>
					<p className='signifier listing-content mt10' dangerouslySetInnerHTML={{ __html: project.heroCaption }} />
				</div>
				<div className='mt40 pb20 bb1'>
					<p className='book small'>{project.projectStatus.name}</p>
				</div>
			</div>
		</Link>
	)
}

const ProjectCard = ({ project }) => {
	return (
		<Link className='link flex' to={'projects/' + project.slug}>
			<div className='project-card w-100 flex flex-col'>
				<div className='ratio-2-1 pos-rel overflow bg-grey'>
					<GatsbyImage className='bg-image image--zoom' image={project.heroImage.gatsbyImageData} alt='Project Image' />
				</div>
				<div className='info'>
					<p className='uppercase book m0 mt10'>{project.name}</p>
					<p className='signifier listing-content mt10' dangerouslySetInnerHTML={{ __html: project.heroCaption }} />
				</div>
				<div className='mta bb1'>
					<p className='book small m0 pb10'>{project.projectStatus.name}</p>
				</div>
			</div>
		</Link>
	)
}
