import Link from "./link";
import * as React from "react"
import Spacer from "../components/spacer"
import ArrowLink from "../components/arrowLink"
import { GatsbyImage } from "gatsby-plugin-image"
import { isMobile } from "react-device-detect"
const Introduction = ({ className, blocks, title }) => {

  const onHover = (event) => {
    if (isMobile) {
      document.querySelector('.hover--number.mobile').innerHTML = event.target.getAttribute('data-image');
      document.querySelector('.hover--title').innerHTML = event.target.getAttribute('data-text');
      document.querySelector('.bg-image.active.mobile').classList.remove('active')
      document.querySelector('.bg-image.mobile[data-image="' + event.target.getAttribute('data-image') + '"]').classList.add('active')
      document.querySelector('.hover--container.mobile').classList.add('active')
    } else {
      document.querySelector('.hover--number').innerHTML = event.target.getAttribute('data-image');
      document.querySelector('.hover--title').innerHTML = event.target.getAttribute('data-text');
      document.querySelector('.bg-image.active').classList.remove('active')
      document.querySelector('.bg-image[data-image="' + event.target.getAttribute('data-image') + '"]').classList.add('active')
      document.querySelector('.hover--container').classList.add('active')
    }
  }
  const hoverOut = () => {
    if (isMobile) {
      document.querySelector('.hover--container.mobile').classList.remove('active')
    } else {
      document.querySelector('.hover--container').classList.remove('active')
    }
  }
  return (
    <div className="introduction--text p40 will--fade m-pb0 pos-rel  ">
      <Spacer />
      <div className="flex">
        <div className="w-100">
          <div className="h3 max-600">
            <p className="content--bolded mb40 m0" dangerouslySetInnerHTML={{ __html: title }}></p>
            At <Link to='/about' data-image='1' data-text={blocks[0].title} role='presentation' onMouseOut={hoverOut} onBlur={hoverOut} onMouseOver={onHover} onFocus={onHover} className="ul-link link op-hover no-p inline-block">MOTIV</Link><sup className="circular">1</sup>, we think outside the box, delivering end-to-end <Link to='/services' data-image='2' data-text={blocks[1].title} role='presentation' onMouseOver={onHover} onMouseOut={hoverOut} onBlur={hoverOut} onFocus={onHover} className="ul-link link no-p inline-block">solutions</Link><sup className="circular">2 </sup>
            that de-risk the sales process and maximise revenues. With over $2.3 billion in collective sales and a strong track record with award-winning developers, our experience-backed, tailored approach uniquely positions each <Link to='/projects' data-image='3' data-text={blocks[2].title} role='presentation' onMouseOut={hoverOut} onBlur={hoverOut} onMouseOver={onHover} onFocus={onHover} className="ul-link no-p link inline-block ">projects</Link><sup className="circular">3</sup> for unparalleled success.
          </div>
          <div className='mt40 pt40'/>
          <div className="link">
            <ArrowLink to="/about" text="Read More" />
          </div>
          <Spacer />
        </div>
        <div className="w-50 pl40 max-900 flex m-hide  " key={1}>
          <div className="mla mt0 max-350 w-100 hover--container">
            <div className="ratio-1-2 bg-grey pos-rel overflow-hidden hover--image">
              <div data-image='1' className="bg-image active">
                <GatsbyImage alt='' className='bg-image' image={blocks[0].image.gatsbyImageData} />
              </div>
              <div data-image='2' className="bg-image">
                <video loop={true} playsInline={true} muted={true} autoPlay={true} data-image='2' className='bg-image'>
                  <source src={blocks[1].videoUrl} />
                </video>
              </div>
              <div data-image='3' className="bg-image">
                <GatsbyImage alt='' data-image='3' className='bg-image' image={blocks[2].image.gatsbyImageData} />
              </div>
            </div>
            <p className="m0 mt20 hover--number mb10 book ">1</p>
            <p className="m0 hover--title signifier">{blocks[0].title}</p>
          </div>
        </div>
        <div className="hover--container introduction--view--box mobile  ">
          <div className="ratio-1-2 bg-grey pos-rel overflow-hidden hover--image">
            <div data-image='1' className="bg-image active mobile">
              <GatsbyImage alt='' className='bg-image mobile' image={blocks[0].image.gatsbyImageData} />
            </div>
            <div data-image='2' className="bg-image mobile">
              <video loop={true} playsInline={true} muted={true} autoPlay={true} data-image='2' style={{ width: '102%', objectFit: 'cover', left: '-1%', height: '101%', top: '-1%' }} className='bg-image mobile'>
                <source src={blocks[1].videoUrl} />
              </video>
            </div>
            <div data-image='3' className="bg-image mobile">
              <GatsbyImage alt='' data-image='3' className='bg-image mobile' image={blocks[2].image.gatsbyImageData} />
            </div>
          </div>
          <p className="m0 mt20 hover--number mobile mb10 book ">1</p>
          <p className="m0 hover--title signifier">{blocks[0].title}</p>
        </div>
      </div>
    </div>
  )
}
export default Introduction
