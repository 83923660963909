import React, { useEffect } from 'react'
import NewsletterForm from '../components/newsletterForm'

const Newsletter = () => {
	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY
			const viewportHeight = window.innerHeight
			if (scrollPosition > viewportHeight * 1.2) {
				document.body.classList.add('newsletter-visible')
			} else {
				document.body.classList.remove('newsletter-visible')
			}
		}
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	const closePopup = () => {
		document.querySelector('.newsletter--modal').classList.add('close');
		setTimeout(() => { document.querySelector('.newsletter--modal').style.display='none';}, 400);
	}

	return (
		<div className='newsletter--modal p40'>
			<div className='close' onClick={closePopup} />
			<div className='title'>
				<p className='h5 m0 text-center mt30 mb30'>Perspectiv</p>
				<p className='circ-std m0 mb20 m-small'>Be the first to receive market-leading news and commentary by subscribing to PERSPECTIV.</p>
			</div>
			<NewsletterForm />
		</div>
	)
}
export default Newsletter
